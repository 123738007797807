const emptyFunc = () => {}

const logger = isLocal ? console.log : console.debug

export const debug = isLocal ? console.debug : emptyFunc
logger.debug = debug

export const info = isLocal ? console.info : emptyFunc
logger.info = info

export const warn = isLocal ? console.warn : emptyFunc
logger.warn = warn

export const error = console.error
logger.error = error

export const log = logger
logger.log = log
window.logger = logger

export { logger }
