import { delegate } from '@/utils/delegate'

function toggleVisibility(ev, target) {
  if (ev?.defaultPrevented) return;
  const selector = target.dataset.toggleVisibility
  const elements = document.querySelectorAll(selector)
  const getInputElement = (current) => {
    if (!current) return null
    if (current.tagName === 'INPUT') return current
    if (current.hasAttribute('for')) {
      current = document.getElementById(current.getAttribute('for'))
      return getInputElement(current)
    }

    return null
  }
  const input = getInputElement(target)
  const checked = input
    ? (
        input.tagName === 'INPUT' && (target.type === 'checkbox' || target.type === 'radio')
          ? !!input.checked
          : (input.type === 'submit' || input.type === 'button')
            ? true
            : !!input.value
      )
    : null

  if (target.checked) {
    elements.forEach(element => {
      element.classList.remove('d_none')
    })
  } else if (checked === null) {
    elements.forEach(element => {
      element.classList.toggle('d_none')
    })
  } else {
    elements.forEach(element => {
      element.classList.add('d_none')
    })
  }
}

let deactivator = null

export function deactivate () {
  deactivator?.()
  deactivator = null
}

export function activate () {
  if (deactivator) return

  const listeners = []

  listeners.push(delegate('click', '[data-toggle-visibility]', toggleVisibility))
  listeners.push(delegate('change', '[data-toggle-visibility]', toggleVisibility))

  document.querySelectorAll('[data-toggle-visibility]').forEach(target => {
    toggleVisibility(null, target)
  })

  deactivator = () => {
    listeners.forEach(fn => fn())
  }

  return deactivate
}
