import { imagePath } from '@/images'
import { delegate, throttle } from '@/utils/delegate'

let collapsed = null

export const activeSidebar = () => {
  let path = document.location.pathname.split('/')[1]
  $(`.sidebar .${path || 'dashboard'}`).addClass('active')
}

export const sidebarCollapse = () => {
  if (collapsed) return
  collapsed = true
  $("body").addClass("sidebar-toggled")
  $(".sidebar").addClass("toggled")
  $('.sidebar-brand img').attr('src', imagePath('ks-logo.png'))
  $('.sidebar .collapse').collapse('hide')
}

export const sidebarExpand = () => {
  if (collapsed === false) return
  collapsed = false
  $("body").removeClass("sidebar-toggled")
  $(".sidebar").removeClass("toggled")
  $('.sidebar-brand img').attr('src', imagePath('ksdeploy-logo.png'))
}

function onSidebarToggle() {
  collapsed ? sidebarExpand() : sidebarCollapse()
}

export const calculateSidebar = throttle(() => {
  window.innerWidth < 1200 ? sidebarCollapse() : sidebarExpand()
}, 100)

export const activate = () => {
  const listener = throttle(calculateSidebar, 100)
  delegate('click', '#sidebarToggle, #sidebarToggleTop', onSidebarToggle)
  window.addEventListener('resize', calculateSidebar, { passive: true })
  calculateSidebar()
  activeSidebar()
  return () => window.removeEventListener('resize', calculateSidebar)
}
