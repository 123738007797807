window.kipuPlugins = window.kipuPlugins || {}

let isReady = false
const queue = []

const isFirstLoad = !window.kipuPlugins.pageLoadersCreated

if(isFirstLoad) {
  window.kipuPlugins.pageLoadersCreated = true

  const runQueue = () => {
    isReady = true
    queue.splice(0).forEach(async (fn) => {
      try {
        await fn()
      } catch (err) {
        console.error(err)
      }
    })
  }

  if (
    document.readyState === 'complete' ||
    document.readyState === 'interactive' ||
    document.readyState === 'loaded'
  ) {
    runQueue()
  } else {
    document.addEventListener('DOMContentLoaded', runQueue)
  }
}

export const pageLoaded = window.pageLoaded || ((fn) => {
  if (isReady) {
    setImmediate(fn)
  } else {
    queue.push(fn)
  }
})

const buildPackLoader = (prefix = 'Kipu') => (fn, keep = true) => {
  let destroy = null
  const load = () => (destroy = fn())

  if (window.kipuReady) load()
  document.addEventListener(`${prefix}Ready`, load)

  const unload = () => {
    try { destroy?.() } catch (err) { console.error(err) }
    if (!keep) {
      document.removeEventListener(`${prefix}Ready `, load)
      document.removeEventListener(`${prefix}Unloaded`, unload)
    }
  }
  document.addEventListener(`${prefix}Unloaded `, unload)

  return () => {
    keep = false
    unload()
  }
}

const buildTrigger = (prefix, key, up, onCall) => {
  const name = `${prefix}${up ? 'Ready' : 'Unloaded'}`
  up = !!up
  return () => {
    window[key] = up
    try { onCall?.() } catch (err) { console.error(err) }
    queueMicrotask(() => document.dispatchEvent(new Event(name)))
  }
}

export const packLoader = window.packLoader || buildPackLoader('Kipu')

export const fireDOMReady = window.fireDOMReady || buildTrigger(
  'Kipu',
  'kipuReady',
  true,
)

export const fireDOMUnloaded = window.fireDOMUnloaded || buildTrigger(
  'Kipu',
  'kipuReady',
)

window.pageLoaded = pageLoaded
window.packLoader = packLoader
window.fireDOMReady = fireDOMReady
window.fireDOMUnloaded = fireDOMUnloaded

if(isFirstLoad && !window.kipuReady) pageLoaded(fireDOMReady)
