import { delegate } from '@/utils/delegate'

function initializeDateTimePicker() {
  $('#datetimepicker').datetimepicker({
    inline: true,
    step: 1,
  });
}

function loadForm(contentId, url) {
  const targetContent = document.getElementById(contentId);

  if (!targetContent) {
    console.error(new Error(`Could not find element with id ${contentId}`));
    return;
  }

  // Clear all form contents
  document.querySelectorAll('.form_content').forEach(content => {
    content.innerHTML = '';
  });

  fetch(url, {
    headers: {
      'X-CSRF-Token': csrfToken()
    }
  })
    .then(response => response.text())
    .then(html => {
      targetContent.innerHTML = html;
      initializeDateTimePicker();
    })
    .catch(error => console.error('Error loading form:', error));
}

function loadCloneForm(ev) {
  // Give the DOM a chance to update so we don't wipe out data while this tab is not active
  setTimeout(() => {
    const target = ev?.delegateTarget || ev?.target || document.findElementById('clone_job_tab')
    if (!target?.classList?.contains("active")) return;
    loadForm('new_clone_job_content', '/release_mains/load_clone_form');
  }, 10)
}

function loadDeploymentForm(ev) {
  // Give the DOM a chance to update so we don't wipe out data while this tab is not active
  setTimeout(() => {
    const target = ev?.delegateTarget || ev?.target || document.findElementById('deployment_tab')
    if (!target?.classList?.contains("active")) return;
    loadForm('new_deployment_content', '/release_mains/load_deployment_form');
  }, 10)
}


export function activate () {
  window.kipuPlugins = window.kipuPlugins || {}

  if (window.kipuPlugins.releaseMainsHandlers) return;
  window.kipuPlugins.releaseMainsHandlers = true;

  delegate('click', '#deployment_tab', loadDeploymentForm)
  delegate('click', '#clone_job_tab', loadCloneForm)

  return packLoader(() => {
    initializeDateTimePicker()

    // Initially load the deployment form
    if (document.getElementById('new_deployment_content')) {
      loadForm('new_deployment_content', '/release_mains/load_deployment_form')
    }
  })
}
