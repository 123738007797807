export function delegate(event, selector, handler) {
  const func = function(event) {
    const closest = event.target.closest(selector)
    if (closest) {
      event.delegateTarget = closest
      handler(event, closest)
    }
  }
  document.addEventListener(event, func)
  return () => document.removeEventListener(event, func)
}

export function debounce(func, wait, immediate) {
  let timeout
  return (...args) => {
    const later = () => {
      timeout = null
      if (!immediate) func(...args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func(...args)
  }
}

export function throttle(func, wait, options) {
  let timeout, lastArgs
  const { leading = true, trailing = true } = options || {}

  return (...args) => {
    if (!timeout) {
      if (leading) func(...args)
      else lastArgs = args
      timeout = setTimeout(() => {
        timeout = null
        if (trailing && lastArgs) func(...args)
      }, wait)
    } else if (trailing) {
      lastArgs = args
    }
  }
}
